import http from "./httpService";
import { getAuthHeader } from "./userService";

const apiEndpoint = "/detail-design";

// =====|  Dashboard Service  |=====

const AuroraService = {
	requestDetailDesign: () => http.post(`${apiEndpoint}/request`, {}, { headers: getAuthHeader() }),
	getUserDetailDesign: () => http.get(`${apiEndpoint}`, { headers: getAuthHeader() }),
};

// =====|  APIs  |=====

export const requestDetailDesign = () => {
	return AuroraService.requestDetailDesign();
};
export const getUserDetailDesign = () => {
	return AuroraService.getUserDetailDesign();
};
